/* eslint-disable @typescript-eslint/no-explicit-any */
import merge from 'lodash/merge';

export const encodeObject = (object: Record<string, unknown>) => {
  const isEncoding = true;
  return transformObject(object, isEncoding);
};

export const decodeObject = (object: Record<string, unknown>) => {
  const isEncoding = false;
  return transformObject(object, isEncoding);
};

const transformObject = (
  object: Record<string, unknown>,
  isEncoding: boolean,
) => {
  const toEntry = (object: Record<string, unknown>, key: string) =>
    [key, object[key]] as [string, unknown];
  const transformEntries = (key: string) =>
    transformEntry(toEntry(object, key), isEncoding);
  const transformedObject = Object.keys(object).map(transformEntries);

  // @ts-expect-error no types for anything
  return merge(...transformedObject);
};

const transformEntry = (entry: [string, unknown], isEncoding: boolean) => {
  const [name, value] = entry;
  const isString = value && typeof value === 'string';
  const isObject = value && typeof value === 'object';

  let operation = (value: unknown) => value;
  if (isObject)
    (operation as (
      object: Record<string, unknown>,
    ) => Record<string, unknown>) = isEncoding ? encodeObject : decodeObject;
  if (isString)
    (operation as (value: string) => string) = isEncoding
      ? encodeString
      : decodeString;

  return { [name]: operation(value) };
};

export const encodeString = (input: string) =>
  window.btoa(encodeUnicode(input));
export const decodeString = (input: string) =>
  decodeUnicode(window.atob(input));

const decodeUnicode = (str: string) =>
  decodeURIComponent(
    str
      .split('')
      .map((c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );

// @ts-expect-error no types for anything
const toSolidBytes = (match: any, p1: any) => String.fromCharCode('0x' + p1);
const encodeUnicode = (str: any) =>
  encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, toSolidBytes);
